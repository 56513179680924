export const navigation = [
  {
    text: 'Qualifikation',
    path: '/qualifikation',
    icon: 'group'
  },
  {
    text: 'Meldeergebnis',
    path: '/meldeergebnis',
    icon: 'user'
  },
  {
    text: 'Protokoll',
    path: '/protokoll',
    icon: 'contentlayout'
  },
 
  
  ];
