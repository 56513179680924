


import React, { useEffect, useState } from 'react';

import DataGrid, {
    Column, Export, Grouping,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { HubConnectionBuilder, HttpTransportType } from '@aspnet/signalr';

import DataSource from 'devextreme/data/data_source';
import { SelectBox } from 'devextreme-react';

export default function Qualifikation() {
 
    const [dataSource, setdataSource] = useState('')
    const [connectionStarted, setconnectionStarted] = useState(false)
    useEffect(() => {


       

    }, []);

    return (
        <React.Fragment>
                
              
                        <SelectBox displayExpr={"text"} valueExpr={"id"}  dataSource={[{id:0,text:"10/11 weiblich"},{id:1,text:"10/11 männlich"},{id:2,text:"08/09 weiblich"},{id:3,text:"08/09 männlich"},{id:4,text:"07 u.Ä. weiblich"},{id:5,text:"07 u.Ä. männlich"}]} placeholder="Bitte Alterklasse auswählen" selectT
                        onValueChanged={function(e){
                            const hubConnection = new HubConnectionBuilder()
                            .withUrl('https://signalr.csdresden.de/fpHub', {
                                skipNegotiation: true,
                                transport: HttpTransportType.WebSockets,
                            })
                            .build();
                
                        const store = new CustomStore({
                
                            load: () => hubConnection.invoke('GetQualifikation',e.value),
                            key: 'swimresultid',
                        });
                        const ds = new DataSource({
                            store: store,
                            reshapeOnPush: true,
                        })
                        hubConnection
                            .start()
                            .then(() => {
                             
                                setdataSource(ds);
                                setconnectionStarted(true);
                
                            });
                
                        }}
                        
                        ></SelectBox>
                  
                {connectionStarted
                    && (<DataGrid
                        id="gridContainer"
                        dataSource={dataSource}
                        showBorders={true}
                        repaintChangesOnly={true}
                        highlightChanges={true}
                        scrolling={{mode:"standard"}}
                        paging={{enabled:false}}
                       
                    >
                         <Export enabled={true} />

 <Column dataField="rank" caption={"Platz"} />
                        <Column dataField="firstname" caption={"Vorname"} />
                        <Column dataField="lastname"  caption={"Nachname"} />
                        <Column dataField="name"  caption={"Verein"} />
                        
                       
                        <Column dataField="stroke"  caption={"Schwimmart"}   />
                        <Column dataField="swimtime"  caption={"Zeit"} customizeText={function(e){return (e.value /1000).toFixed(2)  +' s'}}  />
                        <Column dataField="points"  caption={"Pkt."} sortIndex={1} sortOrder={"desc"} />

                        <Column dataField="swimlevel"  caption={"Bemerkung"} />


                    </DataGrid>)
                }
            
        </React.Fragment>
    );
}

