
import { withNavigationWatcher } from './contexts/navigation';
import { meldeergebnisPage, protokollPage, qualifikationPage } from './pages';

const routes = [

    {
        path: '/qualifikation',
        element: qualifikationPage
    }
    ,
    {
        path: '/meldeergebnis',
        element: meldeergebnisPage
    }
    , {
        path: '/protokoll',
        element: protokollPage
    }
    ,
   
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});
