


import React, { useEffect, useState } from 'react';

import DataGrid, {
    Column, Export, Grouping,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { HubConnectionBuilder, HttpTransportType } from '@aspnet/signalr';

import DataSource from 'devextreme/data/data_source';

export default function Meldeergebnis() {

    const [dataSource, setdataSource] = useState('')
    const [connectionStarted, setconnectionStarted] = useState(false)
    useEffect(() => {

        const hubConnection = new HubConnectionBuilder()
            .withUrl('https://signalr.csdresden.de/fpHub', {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets,
            })
            .build();

        const store = new CustomStore({

            load: () => hubConnection.invoke('GetME'),
            key: 'final_id',
        });
        const ds = new DataSource({
            store: store,
            reshapeOnPush: true,
        })
        hubConnection
            .start()
            .then(() => {

                setdataSource(ds);
                setconnectionStarted(true);

            });


    }, []);

    return (
        <React.Fragment>

            {connectionStarted
                && (<DataGrid
                    id="gridContainer"
                    dataSource={dataSource}
                    showBorders={true}
                    repaintChangesOnly={true}
                    highlightChanges={true}
                    scrolling={{ mode: "standard" }}
                    paging={{ enabled: false }}

                >
                    <Export enabled={true} />
                    <Column dataField="lauf" caption={"Lauf"} groupIndex={0} />
                    <Column dataField="bahn" caption={"Bahn"} sortIndex={0} sortOrder="asc"/>
                    <Column dataField="sa" caption={"Schwimmart"} />
                    <Column dataField="firstname" caption={"Vorname"} />
                    <Column dataField="lastname" caption={"Nachname"} />
                    <Column dataField="name" caption={"Verein"} />



                 
                    <Column dataField="qtime_ms" caption={"Zeit"} customizeText={function (e) { return (e.value / 1000).toFixed(2) + ' s' }} />
                    <Column dataField="qPoints" caption={"Pkt."} />




                </DataGrid>)
            }

        </React.Fragment>
    );
}

